<template>
  <v-container
    grid-list-md
    fill-height
  >
    <v-layout
      row
      wrap
      justify-center
      align-center
    >
      <v-flex xs5>
        <v-card
          class="elevation-12"
          max-width="500px"
        >
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>Agent Log In</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <v-form ref="form">
                  <v-flex xs12>
                    <v-text-field
                      v-model="user.username"
                      prepend-icon="person"
                      name="login"
                      label="Login"
                      type="text"
                      :rules="[v => !!v || 'Username is required']"
                    />
                    <v-text-field
                      id="password"
                      v-model="user.password"
                      prepend-icon="lock"
                      name="password"
                      label="Password"
                      type="password"
                      :rules="[v => !!v || 'Password is required']"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    text-xs-right
                  >
                    <v-radio-group
                      v-model="role"
                      row
                    >
                      <template v-slot:label>
                        <div>Log in as:</div>
                      </template>
                      <v-spacer />
                      <v-radio
                        label="Agent"
                        value="agent"
                      />
                      <v-radio
                        label="Representative"
                        value="rep"
                      />
                    </v-radio-group>
                  </v-flex>
                </v-form>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click.native="login"
            >
              Log in
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
      role: 'agent',
      fail: false,
      errorMessage: 'invalid login, please try again',
    }
  },
  methods: {
    login() {
      if (!this.$refs.form.validate() || !this.role) {
        return
      }
      this.$store.dispatch(`${this.role}Login`, this.user)
        .then(() => {
          this.user.username = ''
          this.user.password = ''
          this.$emit('loginSuccess')
          this.$router.push('/')
        })
        .catch((error) => {
          this.errorMessage = error
          this.fail = true
        })
    },
  },
}
</script>

<style>

</style>
